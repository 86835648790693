import { useCallback } from "react"

const useGAEvent = () => {
	return useCallback((eventName, eventParams = {}) => {
		if(typeof window !== "undefined") {
			window.dataLayer = window.dataLayer || []
			window.dataLayer.push({ event: eventName, ...eventParams })
			console.table("dataLayer:", window.dataLayer)
		}
	}, [])
}

export default useGAEvent
